import {numberDecimals, useToekn} from '@/utils';
import {utils} from 'ethers';
import {abi as IUniswapV2ERC20} from '@uniswap/v2-core/build/IUniswapV2ERC20.json';
let PancakeswapSdk = null;
if (process.env.VUE_APP_CHAIN_ID == 97) {
    PancakeswapSdk = require('@gyro-defi/pancakeswap-sdk');
} else {
    PancakeswapSdk = require('@pancakeswap/sdk');
}

/**
 * Calculate Bond maximum input value
 * @param {Contract} bondTokenContract
 * @param {Contract} bondContract
 * @param {String} account
 */
export function getBondMax(bondTokenContract, bondContract, account) {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async (resolve, reject) => {
        try {
            let bondPrice = await bondContract.bondPriceInUSD();
            const maxGyro = await bondContract.maxPayout();
            let bondBalance = await bondTokenContract.balanceOf(account);

            bondBalance = numberDecimals(parseFloat(utils.formatUnits(bondBalance, 18)));
            bondPrice = numberDecimals(utils.formatUnits(bondPrice, 18));
            let maxBond = numberDecimals(parseFloat(utils.formatUnits(maxGyro, 9)) * bondPrice);
            maxBond = maxBond > bondBalance ? bondBalance : maxBond;
            resolve({maxBond, bondBalance, bondPrice});
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Calculate lp Bond maximum input value
 * @param {Contract} bondTokenContract
 * @param {Contract} BondContract
 * @param {Contract} bondCalculatorContract
 * @param {String} account
 * @param {String} lpToekn
 */
export function getLpBondMax(bondTokenContract, bondContract, bondCalculatorContract, account, lpToekn) {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async (resolve, reject) => {
        try {
            let bondPrice = await bondContract.bondPrice();
            const maxGyro = await bondContract.maxPayout();
            const total = await bondCalculatorContract.getTotalValue(lpToekn);
            const lpTotal = await bondTokenContract.totalSupply();
            let bondBalance = await bondTokenContract.balanceOf(account);

            bondBalance = numberDecimals(parseFloat(utils.formatUnits(bondBalance, 18)));
            bondPrice = numberDecimals(utils.formatUnits(bondPrice, 2));

            let maxBond =
                ((parseFloat(utils.formatUnits(maxGyro, 9)) * bondPrice) / parseFloat(utils.formatUnits(total, 9))) *
                parseFloat(utils.formatUnits(lpTotal, 18));

            maxBond = maxBond > bondBalance ? bondBalance : numberDecimals(maxBond, 1000000);
            resolve({maxBond, bondBalance, bondPrice});
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Acquisition of the loop contract according to the liquidity contract
 * @param {Contract} lpTokenContract
 * @param {provider} provider
 * @returns Promise
 */
export function getLpToken(lpTokenContract, provider) {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async (resolve, reject) => {
        try {
            const tokenAAddress = await lpTokenContract.token0();
            const tokenBAddress = await lpTokenContract.token1();
            const signer = provider.getSigner();
            const A = await useToekn(tokenAAddress, IUniswapV2ERC20, signer);
            const B = await useToekn(tokenBAddress, IUniswapV2ERC20, signer);
            resolve({A, B});
        } catch (error) {
            reject(error);
        }
    });
}

/**
 * Get lp TokenA data information
 * @param {Contract} tokenA
 * @param {Contract} tokenB
 * @param {provider} provider
 * @returns Promise
 */
export function getLpTokenAData(tokenA, tokenB, provider) {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async resolve => {
        try {
            const pair = await PancakeswapSdk.Fetcher.fetchPairData(tokenA, tokenB, provider.getSigner());
            let tokenAPrice = pair.priceOf(tokenA);
            tokenAPrice = tokenAPrice.toSignificant(6);
            resolve(tokenAPrice);
        } catch (error) {
            console.error(error);
            resolve(0);
        }
    });
}
