import {formatFloat, numberDecimals} from '@/utils';
const state = {
    gyro: 0,
    vGyro: 0,
    sGyro: 0,
    pGyro: 0,
    usdt: 0,
    sGyroV1: 0,
    gGyro: 0
};

const mutations = {
    SET_GYRO: (state, gyro) => {
        state.gyro = gyro;
    },
    SET_S_GYRO: (state, sGyro) => {
        state.sGyro = sGyro;
    },
    SET_V_GYRO: (state, vGyro) => {
        state.vGyro = vGyro;
    },
    SET_P_GYRO: (state, pGyro) => {
        state.pGyro = pGyro;
    },
    SET_USDT: (state, usdt) => {
        state.usdt = usdt;
    },
    SET_S_GYRO_V1: (state, sGyroV1) => {
        state.sGyroV1 = sGyroV1;
    },
    SET_G_GYRO: (state, gGyro) => {
        state.gGyro = gGyro;
    }
};

const actions = {
    getAccountBalance({commit}) {
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async resolve => {
            try {
                const {
                    // vGyroContract,
                    account,
                    pGyroContract,
                    gyroContract,
                    sGyroContract,
                    usdtContract,
                    // sGyroV1Contract,
                    gGyroContract
                } = this.state.wallet;
                // Get my gyro balance
                const gyro = await gyroContract.balanceOf(account);
                // Get my pGyro balance
                const pGyro = await pGyroContract.balanceOf(account);
                // Get my vGyro balance
                const vGyro = 0; // await vGyroContract.balanceOf(account);
                // Get my sGyro balance
                const sGyro = await sGyroContract.balanceOf(account);
                // Get my USDT balance
                const usdt = await usdtContract.balanceOf(account);
                // Display V1 sGyro
                const sGyroV1 = 0; // await sGyroV1Contract.balanceOf(account);
                // Get my gGyro balance
                const gGyro = await gGyroContract.balanceOf(account);

                commit('SET_GYRO', numberDecimals(formatFloat(gyro)));
                commit('SET_S_GYRO', numberDecimals(formatFloat(sGyro)));
                commit('SET_V_GYRO', numberDecimals(formatFloat(vGyro)));
                commit('SET_P_GYRO', numberDecimals(formatFloat(pGyro, 18)));
                commit('SET_USDT', numberDecimals(formatFloat(usdt, 18)));
                commit('SET_S_GYRO_V1', numberDecimals(formatFloat(sGyroV1)));
                commit('SET_G_GYRO', numberDecimals(formatFloat(gGyro, 18)));

                resolve(true);
            } catch (error) {
                console.error(error);
                resolve(true);
            }
        });
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
