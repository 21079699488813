<template>
    <header class="header">
        <div class="header-main">
            <div class="logo">
                <svg-icon :icon-class="$store.state.app.theme === 'light' ? 'logo' : 'logo-dark'" />
            </div>
            <div class="navigation">
                <div :class="[index === navIndex ? 'active' : '', 'link', nav.activeMenu]" v-for="(nav, index) in navs" :key="nav.name">
                    <router-link v-if="nav.activeMenu" :to="nav.path">
                        {{ nav.name }}
                    </router-link>
                    <a v-else target="_blank" :href="nav.path">
                        {{ nav.name }}
                        <svg-icon icon-class="external-link" />
                    </a>
                </div>
            </div>
            <div class="wallet">
                <el-button v-if="!$store.state.wallet.account" @click="onLogin" class="account" plain round>{{ $t('page.connectWallet') }}</el-button>
                <el-button v-else class="account" @click="changeVisible = true" round>{{ showAddress }}</el-button>
                <div class="flex-end">
                    <el-dropdown trigger="click" @command="onLanguage">
                        <el-button class="more-theme" circle>{{ showLanguage }}</el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="en">English</el-dropdown-item>
                            <el-dropdown-item command="zh-hans">Chinese</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <!-- <el-switch v-model="language" @change="onLanguage" :width="34" active-text="EN" inactive-text="CN"></el-switch> -->
                    <el-button
                        :icon="$store.state.app.theme === 'light' ? 'el-icon-sunny' : 'el-icon-moon'"
                        @click="onTheme"
                        class="button-theme"
                        circle
                    ></el-button>
                    <el-dropdown trigger="click">
                        <el-button icon="el-icon-more" class="more-theme" circle></el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <svg-icon icon-class="docs" />
                                <a href="https://docs.gyro.money/" class="dropdown-text">{{ $t('page.docs') }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <svg-icon icon-class="code" />
                                <a href="https://github.com/gyro-defi/" class="dropdown-text">{{ $t('page.code') }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <svg-icon icon-class="telegram" />
                                <a href="https://t.me/gyrodao" class="dropdown-text">{{ $t('page.telegram') }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <svg-icon icon-class="vote" />
                                <a href="http://vote.gyro.money/" class="dropdown-text">{{ $t('page.vote') }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <svg-icon icon-class="discord" />
                                <a href="https://discord.gg/B22gZh4jer" class="dropdown-text">{{ $t('page.discord') }}</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <svg-icon icon-class="icon-sGYRO" />
                                <a href="/v1" class="dropdown-text">sGYRO V1</a>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <svg-icon icon-class="buy" />
                                <a
                                    href="https://pancakeswap.finance/swap?outputCurrency=0x1B239ABe619e74232c827FBE5E49a4C072bD869D"
                                    class="dropdown-text"
                                >
                                    {{ $t('page.buyGyro') }}
                                </a>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <el-dialog :title="$t('page.myWallet')" width="70%" top="40vh" append-to-body :visible.sync="changeVisible">
            <div class="logout">
                <div class="address">{{ $store.state.wallet.account }}</div>
                <el-button type="primary" round plain @click="onChange">{{ $t('page.switchWallet') }}</el-button>
            </div>
        </el-dialog>
    </header>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            changeVisible: false,
            navIndex: ''
        };
    },
    created() {
        this.setNavActive();
    },
    computed: {
        showAddress() {
            const account = this.$store.state.wallet.account;
            return `${account.substr(0, 4)}...${account.substr(account.length - 4, 5)}`;
        },
        navs() {
            return [
                {name: this.$t('page.portfolio'), path: '/portfolio', activeMenu: 'portfolio'},
                {name: this.$t('page.vest'), path: '/vest', activeMenu: 'vest'},
                {name: this.$t('page.bond'), path: '/bond', activeMenu: 'bond'},
                {name: this.$t('page.vote'), path: '/vote', activeMenu: 'vote'},
                {name: this.$t('page.old'), path: '/old', activeMenu: 'old'}
            ];
        },
        showLanguage() {
            const nameMap = {
                en: 'EN',
                'zh-hans': 'CN'
            };
            return nameMap[this.$root.$i18n.locale];
        }
    },
    watch: {
        $route() {
            this.setNavActive();
        }
    },
    methods: {
        onLanguage(language) {
            this.$root.$i18n.locale = language;
            this.$store.commit('app/SET_LANGUAGE', language);
        },
        onTheme() {
            const themeName = this.$store.state.app.theme === 'light' ? 'dark' : 'light';
            window.document.body.className = themeName;
            this.$store.commit('app/SET_THEME', themeName);
        },
        async onLogin() {
            await this.$wallet.onConnect();
        },
        async onChange() {
            if (this.$store.state.wallet.account) {
                const web3Modal = this.$wallet.web3Modal;
                await web3Modal.clearCachedProvider();
                await web3Modal.toggleModal();
                if (web3Modal.cachedProvider) this.$wallet.onConnect();
            } else {
                this.$wallet.onConnect();
            }
            this.changeVisible = false;
        },
        setNavActive() {
            const activeMenu = this.$route.meta.activeMenu;
            const index = this.navs.findIndex(item => item.activeMenu === activeMenu);
            if (index !== -1) this.navIndex = index;
        }
    }
};
</script>
<style lang="scss" scoped>
.dropdown-text {
    margin-left: 8px;
}
.header {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    -webkit-box-pack: justify;
    justify-content: space-between;
    position: fixed;
    top: 0px;
    z-index: 2;
    .header-main {
        display: grid;
        grid-template-columns: 120px 1fr 120px;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        top: 0px;
        padding: 1rem;
        z-index: 21;
        position: relative;
    }
    .logo {
        font-size: 24px;
        cursor: pointer;
        width: fit-content;
        justify-content: flex-start;
    }
    .navigation {
        justify-content: flex-start;
        justify-self: center;
        width: fit-content;
        padding: 8px;
        display: grid;
        grid-auto-flow: column;
        gap: 10px;
        overflow: auto;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 30px 60px rgba(57, 57, 57, 0.1);
        border-radius: 12px;
        .link {
            display: flex;
            flex-flow: row nowrap;
            outline: none;
            cursor: pointer;
            text-decoration: none;
            font-size: 16px;
            width: fit-content;
            font-weight: 500;
            word-break: break-word;
            background: #f7f8fa;
            border-radius: 12px;
            a {
                color: #9a9a9d;
                letter-spacing: -0.01em;
                padding: 8px 11px;
                .svg-icon {
                    font-size: 14px;
                }
            }
            &.active {
                box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05), 0px 16px 50px rgba(102, 45, 145, 0.09);
                &.vote {
                    background: linear-gradient(106.24deg, #e85d88 33.12%, #f76997 63.26%) !important;
                }
                &.portfolio {
                    background: linear-gradient(113.55deg, #c32c83 16.31%, #992c89 76.06%) !important;
                }
                &.vest {
                    background: linear-gradient(183.12deg, #6d60b4 2.57%, #54469c 97.42%) !important;
                }
                &.bond {
                    background: linear-gradient(183.12deg, #4c8bd6 2.57%, #72a5f1 97.42%) !important;
                }
                &.old {
                    background: linear-gradient(183.12deg, #6d60b4 2.57%, #54469c 97.42%) !important;
                }
                a {
                    font-weight: 600;
                    color: #fff;
                }
            }
        }
    }
    .wallet {
        display: flex;
        flex-direction: row;
        -webkit-box-align: center;
        align-items: center;
        justify-self: flex-end;
        .flex-end {
            display: flex;
            align-items: center;
            flex: 1;
        }
        .account {
            border-color: #efeeee;
            border-radius: 16px;
            color: #333;
            font-size: 13px;
            padding: 15px 25px;
        }
        .button-theme {
            margin-left: 10px;
        }
        .button-theme,
        .more-theme {
            background: #efeeee;
            border-radius: 16px;
            border: none;
            color: #333;
        }
        .el-switch {
            margin: 0 12px;
        }
        .el-dropdown {
            margin-left: 10px;
        }
    }
}
.logout {
    text-align: center;
    .address {
        font-weight: 600;
        margin-bottom: 20px;
    }
}
</style>
