import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

/* Layout */
import Layout from '@/layout';

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set,
    loginWhite: false            if set true, login will not be validated
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
    {
        path: '/',
        component: Layout,
        redirect: '/portfolio',
        children: [
            {
                path: 'portfolio',
                name: 'portfolio',
                component: () => import('@/pages/portfolio'),
                meta: {activeMenu: 'portfolio'}
            },
            {
                path: 'portfolio/vGyro',
                name: 'portfolioVGyro',
                component: () => import('@/pages/portfolio/exchange/vGyro'),
                meta: {activeMenu: 'portfolio'}
            },
            {
                path: 'portfolio/wrap',
                name: 'portfolioWrap',
                component: () => import('@/pages/portfolio/wrap'),
                meta: {activeMenu: 'portfolio'}
            },
            {
                path: 'portfolio/pGyro',
                name: 'portfolioPGyro',
                component: () => import('@/pages/portfolio/exchange/pGyro'),
                meta: {activeMenu: 'portfolio'}
            },
            {
                path: 'private-sale',
                name: 'privateSale',
                component: () => import('@/pages/private-sale')
            },
            {
                path: 'ido',
                name: 'vgyroOffering',
                component: () => import('@/pages/vgyro-offering'),
                meta: {activeMenu: 'ido'}
            },
            {
                path: 'stake',
                name: 'stake',
                component: () => import('@/pages/stake'),
                meta: {activeMenu: 'stake'},
                redirect: {
                    name: 'stakeDetail'
                },
                children: [
                    {
                        path: 'detail',
                        name: 'stakeDetail',
                        component: () => import('@/pages/stake/detail'),
                        meta: {activeMenu: 'stake'}
                    },
                    {
                        path: 'unstake',
                        name: 'unstakeDetail',
                        component: () => import('@/pages/stake/unstake'),
                        meta: {activeMenu: 'stake'}
                    }
                ]
            },
            {
                path: 'vest',
                name: 'vest',
                component: () => import('@/pages/vest'),
                meta: {activeMenu: 'vest'}
            },
            {
                path: 'old',
                name: 'old',
                component: () => import('@/pages/old'),
                meta: {activeMenu: 'old'},
                redirect: {
                    name: 'oldVe'
                },
                children: [
                    {
                        path: 've',
                        name: 'oldVe',
                        component: () => import('@/pages/old/ve'),
                        meta: {activeMenu: 'old'}
                    },
                    {
                        path: 'bond',
                        name: 'oldBond',
                        component: () => import('@/pages/old/bond'),
                        meta: {activeMenu: 'old'}
                    }
                ]
            },
            {
                path: 'v1',
                name: 'v1',
                component: () => import('@/pages/stake/unstakeV1'),
                meta: {activeMenu: 'v1'}
            },
            {
                path: 'bond',
                name: 'bond',
                meta: {activeMenu: 'bond'},
                component: () => import('@/pages/bond'),
                redirect: {
                    name: 'bondList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'bondList',
                        component: () => import('@/pages/bond/list'),
                        meta: {activeMenu: 'bond'}
                    },
                    {
                        path: 'lp',
                        name: 'bondLp',
                        component: () => import('@/pages/bond/lp'),
                        meta: {activeMenu: 'bond'},
                        redirect: {
                            name: 'bondLpSingle'
                        },
                        children: [
                            {
                                path: 'deposit',
                                name: 'bondLpDeposit',
                                component: () => import('@/pages/bond/lp/deposit'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'single',
                                name: 'bondLpSingle',
                                component: () => import('@/pages/bond/lp/single'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'fast',
                                name: 'bondLpFast',
                                component: () => import('@/pages/bond/lp/fast'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'deposit',
                                name: 'bondLpDeposit',
                                component: () => import('@/pages/bond/lp/deposit'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'redeem',
                                name: 'bondLpRedeem',
                                component: () => import('@/pages/bond/lp/redeem'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'reward',
                                name: 'bondLpReward',
                                component: () => import('@/pages/bond/reward'),
                                meta: {activeMenu: 'bond'}
                            }
                        ]
                    },
                    {
                        path: 'single',
                        name: 'bondSingle',
                        component: () => import('@/pages/bond/single'),
                        meta: {activeMenu: 'bond'},
                        redirect: {
                            name: 'bondSingleDetail'
                        },
                        children: [
                            {
                                path: 'detail',
                                name: 'bondSingleDetail',
                                component: () => import('@/pages/bond/single/detail'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'redeem',
                                name: 'bondSingleRedeem',
                                component: () => import('@/pages/bond/single/redeem'),
                                meta: {activeMenu: 'bond'}
                            },
                            {
                                path: 'reward',
                                name: 'bondSingleReward',
                                component: () => import('@/pages/bond/reward'),
                                meta: {activeMenu: 'bond'}
                            }
                        ]
                    }
                ]
            },
            {
                path: 'vote',
                name: 'vote',
                component: () => import('@/pages/bond'),
                meta: {activeMenu: 'vote'},
                redirect: {
                    name: 'voteList'
                },
                children: [
                    {
                        path: 'list',
                        name: 'voteList',
                        component: () => import('@/pages/bond/list'),
                        meta: {activeMenu: 'vote'}
                    }
                ]
            },
            {
                path: 'code',
                name: 'code',
                redirect: {
                    name: 'codeCreate'
                },
                meta: {activeMenu: 'bond'}
            },
            {
                path: 'code-create',
                name: 'codeCreate',
                component: () => import('@/pages/bond/code'),
                meta: {activeMenu: 'bond'}
            },
            {
                path: 'code-reward',
                name: 'codeReward',
                component: () => import('@/pages/bond/code/reward'),
                meta: {activeMenu: 'bond'}
            },
            {
                path: '404',
                name: '404',
                component: () => import('@/pages/error-page/404'),
                hidden: true,
                meta: {loginWhite: true, breadcrumb: false, title: '404'}
            }
        ]
    },
    {path: '*', redirect: '/404', hidden: true, meta: {breadcrumb: false}}
];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [{path: '*', redirect: '/404', hidden: true, meta: {breadcrumb: false}}];

const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        scrollBehavior: () => ({y: 0}),
        routes: [...constantRoutes, ...asyncRoutes]
    });

const router = createRouter();

export default router;
