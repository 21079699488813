import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {ApolloClient, InMemoryCache} from '@apollo/client/core';
import {createApolloProvider} from '@vue/apollo-option';
import {APOLLO_API_URL} from '@/connectors/config';

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
    cache,
    uri: APOLLO_API_URL || ''
});

Vue.use(VueApollo);

export const apolloProvider = createApolloProvider({
    defaultClient: apolloClient
});
