import {Contract} from 'ethers';
import {GYRO_ADDRESSES, TOKEN_ADDRESSES, MULTICALL_ADDRESS, CHAIN_ID} from '@/connectors/config';
import PrivateSaleAbi from '@/abis/PrivateSale';
import pGyroAbi from '@/abis/pGyro';
import vGyroOfferingAbi from '@/abis/vGyroOffering';
import vGyroAbi from '@/abis/vGyro';
import sGyroAbi from '@/abis/sGyro';
import gGyroAbi from '@/abis/gGyro';
import UsdtAbi from '@/abis/usdt';
import pGyroVaultAbi from '@/abis/pGyroVault';
import vGyroVaultAbi from '@/abis/vGyroVault';

import GyroVaultAbi from '@/abis/GyroVault';
import ReferralAbi from '@/abis/Referral';
import GyroAbi from '@/abis/Gyro';
import BondCalculatorAbi from '@/abis/BondCalculator';

import ReservoirAbi from '@/abis/Reservoir';

import VeGyroAbi from '@/abis/veGyro';
import VoterAbi from '@/abis/Voter';
import veGyroDistAbi from '@/abis/veGyroDist';
import GyroMinterAbi from '@/abis/GyroMinter';

import DistributorAbi from '@/abis/Distributor';
import {abi as IUniswapV2PairABI} from '@uniswap/v2-core/build/IUniswapV2Pair.json';

import {Multicall} from 'ethereum-multicall';

const state = {
    slippage: localStorage.getItem('slippage') || 0.5,
    account: '' || localStorage.getItem('account'), // Account address
    recipientAddress: '' || localStorage.getItem('recipientAddress'), // Account address
    walletProvider: null, // wallet provider
    provider: null, // ethers provider
    chainId: '', // chainId
    blockNumber: '', // block Number
    usdtContract: null, // usdt Contract
    busdContract: null, // busd Contract
    pGyroContract: null, // pGyro Contract
    privateSaleContract: null,
    vGyroContract: null,
    vGyroOfferingContract: null,
    gyroContract: null,
    vGyroVaultContract: null,
    pGyroVaultContract: null,
    sGyroContract: null,
    gGyroContract: null,
    sGyroV1Contract: null,
    gyroVaultContract: null,
    distributorContract: null,
    lpTokenContract: null,
    lpBondCalculatorContract: null,
    referralContract: null,
    reservoirContract: null,
    multicall: null,
    veGyroContract: null,
    voterContract: null,
    veGyroDistContract: null,
    GyroMinterContract: null
};

const mutations = {
    RECIPINET_ADDRESS: (state, recipientAddress) => {
        state.recipientAddress = recipientAddress;
        localStorage.setItem('recipientAddress', recipientAddress);
    },
    SET_ACCOUNT: (state, account) => {
        state.account = account;
        localStorage.setItem('account', account);
    },
    SET_PROVIDER: (state, provider) => {
        state.provider = provider;
    },
    SET_WALLETPROVIDER: (state, walletProvider) => {
        state.walletProvider = walletProvider;
    },
    SET_CHAINID: (state, chainId) => {
        state.chainId = chainId;
        localStorage.setItem('chainId', chainId);
    },
    SET_BLOCKNUMBER: (state, blockNumber) => {
        state.blockNumber = blockNumber;
    },
    SET_PRIVATE_SALE_CONTRACT: (state, privateSaleContract) => {
        state.privateSaleContract = privateSaleContract;
    },
    SET_PGYRO_CONTRACT: (state, pGyroContract) => {
        state.pGyroContract = pGyroContract;
    },
    SET_VGYRO_OFFERING_CONTRACT: (state, vGyroOfferingContract) => {
        state.vGyroOfferingContract = vGyroOfferingContract;
    },
    SET_VGYRO_CONTRACT: (state, vGyroContract) => {
        state.vGyroContract = vGyroContract;
    },
    SET_GGYRO_CONTRACT: (state, gGyroContract) => {
        state.gGyroContract = gGyroContract;
    },
    SET_USDT_CONTRACT: (state, usdtContract) => {
        state.usdtContract = usdtContract;
    },
    SET_GYRO_CONTRACT: (state, gyroContract) => {
        state.gyroContract = gyroContract;
    },
    SET_VGYRO_VAULT_CONTRACT: (state, vGyroVaultContract) => {
        state.vGyroVaultContract = vGyroVaultContract;
    },
    SET_PGYRO_VAULT_CONTRACT: (state, pGyroVaultContract) => {
        state.pGyroVaultContract = pGyroVaultContract;
    },
    SET_SGYRO_CONTRACT: (state, sGyroContract) => {
        state.sGyroContract = sGyroContract;
    },
    SET_SGYRO_V1_CONTRACT: (state, sGyroV1Contract) => {
        state.sGyroV1Contract = sGyroV1Contract;
    },
    SET_GYRO_VAULT_CONTRACT: (state, gyroVaultContract) => {
        state.gyroVaultContract = gyroVaultContract;
    },
    SET_SLIPPAGE: (state, slippage) => {
        state.slippage = slippage;
        localStorage.setItem('slippage', slippage);
    },
    SET_DISTRIBUTOR_CONTRACT: (state, distributorContract) => {
        state.distributorContract = distributorContract;
    },
    SET_LP_TOEKN_CONTRACT: (state, lpTokenContract) => {
        state.lpTokenContract = lpTokenContract;
    },
    SET_LP_BOND_CALAULATOR_CONTRACT: (state, lpBondCalculatorContract) => {
        state.lpBondCalculatorContract = lpBondCalculatorContract;
    },
    SET_REFERRAL_CONTRACT: (state, referralContract) => {
        state.referralContract = referralContract;
    },
    SET_RESERVOIR_CONTRACT: (state, reservoirContract) => {
        state.reservoirContract = reservoirContract;
    },
    SET_MULTICALL: (state, multicall) => {
        state.multicall = multicall;
    },
    SET_VE_GYRO_CONTRACT: (state, veGyroContract) => {
        state.veGyroContract = veGyroContract;
    },
    SET_VOTER_CONTRACT: (state, voterContract) => {
        state.voterContract = voterContract;
    },
    SET_VE_DIST_GYRO: (state, veGyroDistContract) => {
        state.veGyroDistContract = veGyroDistContract;
    },
    SET_GYRO_MINTER_CONTRACT: (state, GyroMinterContract) => {
        state.GyroMinterContract = GyroMinterContract;
    }
};

const actions = {
    login({commit}, {provider, account, chainId}) {
        const signer = provider.getSigner();
        commit('SET_ACCOUNT', account);
        commit('SET_CHAINID', chainId);

        if (TOKEN_ADDRESSES.USDT) commit('SET_USDT_CONTRACT', new Contract(TOKEN_ADDRESSES.USDT, UsdtAbi, signer));

        if (GYRO_ADDRESSES.PrivateSale) commit('SET_PRIVATE_SALE_CONTRACT', new Contract(GYRO_ADDRESSES.PrivateSale, PrivateSaleAbi, signer));
        if (TOKEN_ADDRESSES.pGYRO) commit('SET_PGYRO_CONTRACT', new Contract(TOKEN_ADDRESSES.pGYRO, pGyroAbi, signer));

        if (GYRO_ADDRESSES.VGyroOffering) commit('SET_VGYRO_OFFERING_CONTRACT', new Contract(GYRO_ADDRESSES.VGyroOffering, vGyroOfferingAbi, signer));
        if (TOKEN_ADDRESSES.vGYRO) commit('SET_VGYRO_CONTRACT', new Contract(TOKEN_ADDRESSES.vGYRO, vGyroAbi, signer));
        if (TOKEN_ADDRESSES.gGYRO) commit('SET_GGYRO_CONTRACT', new Contract(TOKEN_ADDRESSES.gGYRO, gGyroAbi, signer));

        if (TOKEN_ADDRESSES.GYRO) commit('SET_GYRO_CONTRACT', new Contract(TOKEN_ADDRESSES.GYRO, GyroAbi, signer));
        if (GYRO_ADDRESSES.VGyroVault) commit('SET_VGYRO_VAULT_CONTRACT', new Contract(GYRO_ADDRESSES.VGyroVault, vGyroVaultAbi, signer));
        if (GYRO_ADDRESSES.PGyroVault) commit('SET_PGYRO_VAULT_CONTRACT', new Contract(GYRO_ADDRESSES.PGyroVault, pGyroVaultAbi, signer));

        if (TOKEN_ADDRESSES.sGYRO) commit('SET_SGYRO_CONTRACT', new Contract(TOKEN_ADDRESSES.sGYRO, sGyroAbi, signer));
        if (GYRO_ADDRESSES.GyroVault) commit('SET_GYRO_VAULT_CONTRACT', new Contract(GYRO_ADDRESSES.GyroVault, GyroVaultAbi, signer));

        if (GYRO_ADDRESSES.Distributor) commit('SET_DISTRIBUTOR_CONTRACT', new Contract(GYRO_ADDRESSES.Distributor, DistributorAbi, signer));

        if (GYRO_ADDRESSES.LpToken) commit('SET_LP_TOEKN_CONTRACT', new Contract(GYRO_ADDRESSES.LpToken, IUniswapV2PairABI, signer));

        if (GYRO_ADDRESSES.Referral) commit('SET_REFERRAL_CONTRACT', new Contract(GYRO_ADDRESSES.Referral, ReferralAbi, signer));

        if (GYRO_ADDRESSES.BondCalculator)
            commit('SET_LP_BOND_CALAULATOR_CONTRACT', new Contract(GYRO_ADDRESSES.BondCalculator, BondCalculatorAbi, signer));

        if (GYRO_ADDRESSES.Reservoir) commit('SET_RESERVOIR_CONTRACT', new Contract(GYRO_ADDRESSES.Reservoir, ReservoirAbi, signer));
        if (GYRO_ADDRESSES.veGyro) commit('SET_VE_GYRO_CONTRACT', new Contract(GYRO_ADDRESSES.veGyro, VeGyroAbi, signer));
        if (GYRO_ADDRESSES.Voter) commit('SET_VOTER_CONTRACT', new Contract(GYRO_ADDRESSES.Voter, VoterAbi, signer));
        if (GYRO_ADDRESSES.veGyroDist) commit('SET_VE_DIST_GYRO', new Contract(GYRO_ADDRESSES.veGyroDist, veGyroDistAbi, signer));
        if (GYRO_ADDRESSES.GyroMinter) commit('SET_GYRO_MINTER_CONTRACT', new Contract(GYRO_ADDRESSES.GyroMinter, GyroMinterAbi, signer));
        // v1 sGyro Contract
        commit(
            'SET_SGYRO_V1_CONTRACT',
            new Contract(
                CHAIN_ID == 97 ? '0xdbF86Ba5Aa8f49A6501E94a24147Bb771Ea3f8c9' : '0xDC93Eb0eB1bF2aC6Da14b3ee54a8d7fBb15bB058',
                sGyroAbi,
                signer
            )
        );

        if (provider)
            commit(
                'SET_MULTICALL',
                new Multicall({
                    ethersProvider: provider,
                    tryAggregate: true,
                    multicallCustomContractAddress: MULTICALL_ADDRESS
                })
            );
        commit('SET_PROVIDER', provider);
    },
    logout({commit}) {
        commit('SET_ACCOUNT', '');
        commit('SET_PROVIDER', null);
        commit('SET_WALLETPROVIDER', null);
        commit('SET_CHAINID', '');
        commit('SET_PRIVATE_SALE_CONTRACT', null);
        commit('SET_PGYRO_CONTRACT', null);
        commit('SET_VGYRO_OFFERING_CONTRACT', null);
        commit('SET_VGYRO_CONTRACT', null);
        commit('SET_USDT_CONTRACT', null);
        localStorage.removeItem('account');
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
