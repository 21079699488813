const state = {
    // language
    language: localStorage.getItem('language') || 'en',
    theme: localStorage.getItem('theme') || 'light',
    lpMode: false
};

const mutations = {
    SET_LANGUAGE: (state, language) => {
        state.language = language;
        localStorage.setItem('language', language);
    },
    SET_THEME: (state, theme) => {
        state.theme = theme;
        localStorage.setItem('theme', theme);
    },
    SET_LP_MODE: (state, lpMode) => {
        state.lpMode = lpMode;
    }
};

const actions = {
    setLanguage({commit}, language) {
        commit('SET_LANGUAGE', language);
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
