<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    name: 'app',
    mounted() {
        const themeName = localStorage.getItem('theme') || 'light';
        window.document.body.className = themeName;
    }
};
</script>
<style lang="scss"></style>
