const state = {
    bondNftList: [],
    guages: [],
    bondNFTS: []
};

const mutations = {
    SET_BOND_NFT_LIST: (state, bondNftList) => {
        state.bondNftList = bondNftList;
    },
    SET_GUAGES: (state, guages) => {
        state.guages = guages;
    },
    SET_BOND_NFTS: (state, bondNFTS) => {
        state.bondNFTS = bondNFTS;
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions
};
