import {Contract, utils} from 'ethers';
import {Token} from '@pancakeswap/sdk';
import {CHAIN_ID} from '@/connectors/config';

/**
 * Create a contract object according to the configuration
 * @param {String} address
 * @param {JSON} abi
 * @param {Object} signer
 * @returns Contract
 */
export function useTokenContract(address, abi, signer) {
    return new Contract(address, abi, signer);
}

/**
 * Get the token information through the address
 * @param {String} address
 * @param {JSON} abi
 * @param {Object} signer
 * @returns
 */
export function getToken(address, abi, signer) {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async (resolve, reject) => {
        const tokenContract = useTokenContract(address, abi, signer);
        const name = await tokenContract.name();
        const symbol = await tokenContract.symbol();
        const decimals = await tokenContract.decimals();
        if (name && symbol && decimals) return resolve({name, symbol, decimals});
        reject();
    });
}

/**
 * Set token
 * @param {String} address
 * @param {JSON} abi
 * @param {Object} signer
 * @returns
 */
export function useToekn(address, abi, signer) {
    /* eslint-disable no-async-promise-executor */
    return new Promise(async (resolve, reject) => {
        const {name, symbol, decimals} = await getToken(address, abi, signer);
        if (name && symbol && decimals) return resolve(new Token(CHAIN_ID, address, decimals, symbol, name));
        reject();
    });
}

/**
 * Keep four decimals
 * @param {Number} num
 * @param {Number} decimals 10000
 */
export function numberDecimals(num, decimals = 10000) {
    return Math.floor(num * decimals) / decimals;
}

/**
 * BigNumber -> float
 * @param {BigNumber} value
 * @param {Number} wei default：9
 */
export function formatFloat(value, wei = 9) {
    return parseFloat(utils.formatUnits(value, wei));
}

/**
 * Generate random code
 * @param {Number} e length
 * @returns String
 */
export function randomCode(e = 6) {
    var t = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678',
        a = t.length,
        n = '';
    for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
    return n;
}

/**
 * Format second
 * @param {Number} second
 * @param {String} daysText
 * @param {String} hoursText
 * @returns String
 */
export function formatSecond(second, daysText, hoursText) {
    if (second <= 0) return `0 ${daysText}`;
    const days = Math.floor(second / (24 * 3600));
    const leave1 = second % (24 * 3600);
    const hours = Math.floor(leave1 / 3600);
    return hours === 0 ? `${days} ${daysText}` : `${days} ${daysText}, ${hours} ${hoursText}`;
}

/**
 * Format Date
 * @param {String} fmt yyyy-MM-dd HH:mm
 * @param {Date} date
 * @returns
 */
export function formatDate(date, fmt = 'yyyy-MM-dd HH:mm') {
    var o = {
        'M+': date.getMonth() + 1, //Month
        'd+': date.getDate(), //Date
        'H+': date.getHours(), //Hours
        'm+': date.getMinutes(), //Minutes
        's+': date.getSeconds(), //Seconds
        'q+': Math.floor((date.getMonth() + 3) / 3), //Quarter
        S: date.getMilliseconds() //millisecond
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
    for (var k in o)
        if (new RegExp('(' + k + ')').test(fmt))
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
    return fmt;
}

/**
 * Judging whether it is a mobile
 * @returns
 */
export function isMobile() {
    return navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    );
}
