/* eslint-disable no-async-promise-executor */
/**
 * Wallet connection
 */
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import store from '../store';
import {MessageBox} from 'element-ui';
import {providers} from 'ethers';
import {NETWORK_CONFIG, BASE_BSC_SCAN_URL, CHAIN_ID} from './config';

class WalletConnection {
    constructor() {
        this.blockNumberTime = null;
        this.modalProvider = null;

        const providerOptions = {
            walletconnect: {
                package: WalletConnectProvider, // required
                options: {
                    rpc: NETWORK_CONFIG
                }
            }
        };

        this.web3Modal = new Web3Modal({
            cacheProvider: true,
            providerOptions // required
        });

        this.onConnect();
    }
    // Link wallet
    async onConnect() {
        const self = this;
        try {
            this.modalProvider = await this.web3Modal.connect();

            // Subscribe to accounts change
            this.modalProvider.on('accountsChanged', accounts => {
                if (accounts && accounts[0] && store.state.wallet.account.toLowerCase() != accounts[0].toLowerCase()) window.location.reload();
            });

            // Subscribe to chainId change
            this.modalProvider.on('chainChanged', chainId => {
                const chainIdNum = Number(chainId).toString(10);
                if (!this.isNetwork(chainIdNum)) self.showNetwork();
                console.log(chainIdNum);
            });

            // Subscribe to provider connection
            this.modalProvider.on('connect', info => {
                console.log(info);
            });

            // Subscribe to provider disconnection
            this.modalProvider.on('disconnect', error => {
                console.error(error);
                store.dispatch('wallet/logout');
                clearInterval(self.blockNumberTime);
            });
            await this.fetchAccountData(this.modalProvider);
        } catch (error) {
            store.dispatch('wallet/logout');
            console.error(error);
        }
    }

    /**
     * Kick in the UI action after Web3modal dialog has chosen a provider
     */
    async fetchAccountData(modalProvider) {
        const web3Provider = new Web3(modalProvider);
        const provider = new providers.Web3Provider(web3Provider.currentProvider);
        const network = await provider.getNetwork();
        const signer = provider.getSigner();
        const account = await signer.getAddress();
        if (!this.isNetwork(network.chainId)) return this.showNetwork('Please switch to BSC main network?');
        if (account && network.chainId && provider) store.dispatch('wallet/login', {provider, account, chainId: network.chainId});
        this.getBlockNumber(provider);
    }

    /**
     * Disconnect wallet button pressed.
     */
    async onDisconnect() {
        console.log('Killing the wallet connection', this.modalProvider);

        // TODO: Which providers have close method?
        if (this.provider.close) {
            await this.modalProvider.close();

            // If the cached provider is not cleared,
            // WalletConnect will default to the existing session
            // and does not allow to re-scan the QR code with a new wallet.
            // Depending on your use case you may want or want not his behavir.
            await this.web3Modal.clearCachedProvider();
            this.modalProvider.provider = null;
        }
        store.dispatch('wallet/logout');
        clearInterval(this.blockNumberTime);
    }

    // Judging whether it is a configuration network
    isNetwork(chainId) {
        if (chainId == CHAIN_ID) return true;
        return false;
    }

    // set main network
    async setNetwork() {
        const provider = window.ethereum;
        const chainId = parseInt(CHAIN_ID, 10);
        try {
            await provider.request({
                method: 'wallet_addEthereumChain',
                params: [
                    {
                        chainId: `0x${chainId.toString(16)}`,
                        chainName: 'Binance Smart Chain Mainnet',
                        nativeCurrency: {
                            name: 'BNB',
                            symbol: 'bnb',
                            decimals: 18
                        },
                        rpcUrls: [NETWORK_CONFIG[CHAIN_ID]],
                        blockExplorerUrls: [`${BASE_BSC_SCAN_URL}/`]
                    }
                ]
            });
            window.location.reload();
        } catch (error) {
            console.error('Failed to setup the network in Metamask:', error);
        }
    }

    // Switch the main network ID
    showNetwork(text) {
        MessageBox(text || 'You confirm that you want to switch to other networks?', 'network', {
            confirmButtonText: 'confirm',
            cancelButtonText: 'cancel'
        })
            .then(() => {
                this.setNetwork();
            })
            .catch(() => {});
    }
    // Get the latest block height
    async getBlockNumber(provider) {
        const firstBlockNumber = await provider.getBlockNumber();
        if (firstBlockNumber) {
            store.commit('wallet/SET_BLOCKNUMBER', firstBlockNumber);
        }
        let isUpdateDash = true;
        let isUpdateBalance = true;
        provider.on('block', async blockNumber => {
            store.commit('wallet/SET_BLOCKNUMBER', blockNumber);
            if (isUpdateBalance) {
                isUpdateBalance = false;
                isUpdateBalance = await store.dispatch('account/getAccountBalance');
            }
            if (isUpdateDash) {
                isUpdateDash = false;
                isUpdateDash = await store.dispatch('dash/updateDash');
            }
        });
    }
}

export default WalletConnection;
