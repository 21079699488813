import tokenLists from './tokenLists';

// Trading pool contract address
const POOLS_CONFIG = {
    [56]: {
        PrivateSale: '0xE57F45482cC5164686B2A7070Baa72F8A4BB59eB',
        VGyroOffering: '0x726477a080765745873C3d2012556eAc188A181f',
        VGyroVault: '0x214DD50C57e41913D6A366d8B49295d072913e07',
        PGyroVault: '0x1DC21e093D933971C45Af678f0baE384c343f8dE',
        GyroVault: '0x96a8FC2D80F464Bd734Ce8ED31Bd34a48176e5a7',
        Distributor: '0xe863EF77b34CB50Ca379d4b33f252751c0c69825',
        LpToken: '0x5ca063a7e2bebefeb2bdea42158f5b825f0f9ffb',
        BondCalculator: '0xF14a3eefa14C43E2FfF341F8B40BEe924121FEC5',
        Referral: '0x9116cb9930e0f7C5bc35e33f17F6b892Af6E763a',
        Reservoir: '0x8B1522402FECe066d83E0F6C97024248Be3C8c01',
        veGyro: '0xeB84193D6d8ebfEd5848517a923b8DC84F3De0A7',
        veGyroDist: '0xB1868DAE1491af11C7f7C75b39134aE2afCb833a',
        Voter: '0x72F782Beb4A859e2B2a23227Be1F9f2275171aF9',
        GyroMinter: '0x9C7dC99B94e796144feB03F5D031a7d4AD186d12'
    },
    [97]: {
        PrivateSale: '0xC4Bb61D7149F455185674285472921336E856856',
        VGyroOffering: '0x200231809A69219eDD12269F42d9AF17F7FF22EE',
        VGyroVault: '0x61B034746072eBC3254Fd818153A47344F203EAB',
        PGyroVault: '0xf70a0E585E024954157A382b0Fd3455f6D49c1c5',
        GyroVault: '0xFb62A381eC59cF4844F01c65C6d31AC42Cc64ab8',
        Distributor: '0x472198C10b3720C8aF25031e7Fd3a313eFbdFfbF',
        LpToken: '0x37dce4361cc268bd35817c7e6c025f90beae24e4',
        BondCalculator: '0x7054c403beB802e8cB28743042a682F7C399F536',
        Referral: '0x2B72690683Ca1400C0ac569414eeE7E4C71F4bfe',
        Reservoir: '0xB8260AFf40321Afe86B5618D70cB34D661B9b3b1',
        veGyro: '0xfc20F9f50eCFF9890e6e83d4dFf417ccAA7481c3',
        veGyroDist: '0x7cA1cfa355329E0Eb70B615824a4CE5B0612c971',
        Voter: '0x29c49aB79dD7F170D11f065504062e488C87C8de',
        GyroMinter: '0x16f6C8FC142Aef869937B7327cC2Ac108bD5c051'
    }
};

// bond contract address
const BOND_CONFIG = {
    [56]: [
        // {
        //     bondAddr: '0x8885e2493ef85bf5e75DbD2Db5047b4Eb72d47Ae',
        //     tokenAddr: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
        //     type: 'single',
        //     name: 'BUSD',
        //     icon: 'BUSD'
        // },
        {
            bondAddr: '0x5dfB3Db75d3a29D8587fa49020806EF0f429B578',
            tokenAddr: '0xA5399084a5F06d308C4527517bbB781c4dCe887c',
            bondHelperAddr: '0x170Da1A957e816aA030dE37b71Ba0e9746afC5eF',
            singleTokenAddr: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            type: 'lp',
            name: 'GYRO/BUSD',
            icon: 'GYRO-BUSD'
        },
        {
            bondAddr: '0x9987a74165224Dd150110B8a97650c48BfB3D67C',
            tokenAddr: '0x5ca063a7e2bebefeb2bdea42158f5b825f0f9ffb',
            bondHelperAddr: '0x170Da1A957e816aA030dE37b71Ba0e9746afC5eF',
            singleTokenAddr: '0x55d398326f99059ff775485246999027b3197955',
            type: 'lp',
            name: 'GYRO/USDT',
            icon: 'GYRO-USDT'
        },
        {
            bondAddr: '0x709D2d550536D7077387168804f0F3E9b3bc633B',
            tokenAddr: '0x54C182A3c14590b3B8211e4FB831c0F0b572C825',
            bondHelperAddr: '0x170Da1A957e816aA030dE37b71Ba0e9746afC5eF',
            singleTokenAddr: '0xd17479997F34dd9156Deef8F95A52D81D265be9c',
            type: 'lp',
            name: 'GYRO/USDD',
            icon: 'GYRO-USDT'
        }
    ],
    [97]: [
        {
            bondAddr: '0x7b05B420158DE30A5f72B233f314e9aEa09979eD',
            tokenAddr: '0x37dce4361cc268bd35817c7e6c025f90beae24e4',
            bondHelperAddr: '0x4208a70C07717F6dCa090B041eA14b848418657f',
            singleTokenAddr: '0xDAb5D4634DA56f3d5E84DC9E908BF9F7b8610E27',
            type: 'lp',
            name: 'GYRO/USDT',
            icon: 'GYRO-USDT',
            rewardSymbol: ['USDT', 'GYRO'],
            key: 'GU-U'
        },
        {
            bondAddr: '0x043BEC83FbA249F0427AcCa1F7A0eF73149dA4E4',
            tokenAddr: '0xDAb5D4634DA56f3d5E84DC9E908BF9F7b8610E27',
            type: 'single',
            name: 'USDT',
            icon: 'USDT',
            rewardSymbol: ['GYRO'],
            key: 'U-G'
        }
        // {
        //     bondAddr: '0x21D31B304CdA3D8Ad11f1EaE4b6a04efddfB8104',
        //     tokenAddr: '0x37dce4361cc268bd35817c7e6c025f90beae24e4',
        //     bondHelperAddr: '0x4208a70C07717F6dCa090B041eA14b848418657f',
        //     singleTokenAddr: '0xDAb5D4634DA56f3d5E84DC9E908BF9F7b8610E27',
        //     type: 'lp',
        //     name: 'GYRO/USDT',
        //     icon: 'GYRO-USDT',
        //     rewardSymbol: 'GYRO',
        //     key: 'GU-G'
        // },
        // {
        //     bondAddr: '0x6C4190D50EBd9570e4d9e16d9dcD249826D4a590',
        //     tokenAddr: '0xce2dd0ccb44ac889bda86bee6d55b81e6c26aa17',
        //     bondHelperAddr: '0x4208a70C07717F6dCa090B041eA14b848418657f',
        //     singleTokenAddr: '0x66654e651D15c525297cC942E5312327a7F0AF81',
        //     type: 'lp',
        //     name: 'GYRO/BUSD',
        //     icon: 'GYRO-BUSD',
        //     rewardSymbol: 'GYRO',
        //     key: 'GB-G'
        // }
    ]
};

// System reward cycle
export const REWARD_PERIOD = 1 * 86400;

// Main network configuration
export const NETWORK_CONFIG = {
    [56]: 'https://bsc-dataseed.binance.org:443',
    [97]: 'https://data-seed-prebsc-1-s1.binance.org:8545'
};

export const BASE_BSC_SCAN_URLS = {
    [56]: 'https://bscscan.com',
    [97]: 'https://testnet.bscscan.com'
};

// Statistics interface address
export const APOLLO_API = {
    [56]: 'https://api.thegraph.com/subgraphs/name/gyro-defi/gyro-v2',
    [97]: 'https://api.thegraph.com/subgraphs/name/gyro-defi/gyro-v2'
};

// multicall multicall address
export const MULTICALL_CONFIG_ADDRESS = {
    [56]: '0xC50F4c1E81c873B2204D7eFf7069Ffec6Fbe136D',
    [97]: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576'
};

// chain id
export const CHAIN_ID = process.env.VUE_APP_CHAIN_ID;

// network url
export const NETWORK_URL = process.env.VUE_APP_NETWORK_URL || '';

// Get the Token Contract Address
function constructSameTokenAddressMap(chainId) {
    const token = {};
    tokenLists.tokens.forEach(element => {
        if (element.chainId == chainId) token[element.symbol] = element.address;
    });
    return token;
}

// Get the Map Contract Address
function constructSameAddressMap(map, chainId) {
    return map[[chainId]];
}

/**
 * Number of digits according to the token
 * @param {String} comparison comparison value
 * @param {String} type comparison key Default: 'address' Optional: ['address', 'symbol']
 * @returns Number
 */
export function getDecimals(comparison, type = 'address') {
    const token = tokenLists.tokens.find(element => element[type] === comparison);
    if (token) return token.decimals;
    return '';
}

/**
 * According to the symbol query token
 * @param {String} symbol symbol value
 * @returns Token
 */
export function getSymbol(symbol) {
    const tokens = [];
    console.log(tokenLists);
    tokenLists.tokens.forEach(element => {
        if (element.chainId == CHAIN_ID) tokens.push(element);
    });
    const token = tokens.find(element => element.symbol.toLowerCase() === symbol.toLowerCase());
    if (token) return token;
    return '';
}

/**
 * According to the address query token
 * @param {String} address address value
 * @returns Token
 */
export function getToken(address) {
    const tokens = [];
    tokenLists.tokens.forEach(element => {
        if (element.chainId == CHAIN_ID) tokens.push(element);
    });
    const token = tokens.find(element => element.address.toLowerCase() === address.toLowerCase());
    if (token) return token;
    return '';
}

export const TOKEN_ADDRESSES = CHAIN_ID ? constructSameTokenAddressMap(CHAIN_ID) : [];

export const GYRO_ADDRESSES = CHAIN_ID ? constructSameAddressMap(POOLS_CONFIG, CHAIN_ID) : [];

export const BOND_ADDRESSES = CHAIN_ID ? constructSameAddressMap(BOND_CONFIG, CHAIN_ID) : [];

export const APOLLO_API_URL = CHAIN_ID ? constructSameAddressMap(APOLLO_API, CHAIN_ID) : [];

export const MULTICALL_ADDRESS = CHAIN_ID ? constructSameAddressMap(MULTICALL_CONFIG_ADDRESS, CHAIN_ID) : [];

export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[CHAIN_ID];
